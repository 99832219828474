<script setup lang="ts">
import { Region } from '@/stores/RegionStore'

defineProps({
  disableCountries: {
    type: Boolean,
    default: false,
  },
})

const flags = {
  be: await import('~/assets/images/countries/be.svg'),
  nl: await import('~/assets/images/countries/nl.svg'),
  lu: await import('~/assets/images/countries/lu.svg'),
}

const regionStore = useRegionStore()

const { t, availableLocales, locale, setLocale } = useI18n()
const localeContext = ref(locale)

const languageSelect = ref(null)
const countrySelect = ref(null)
const languagesOpen = ref(false)
const countriesOpen = ref(false)
// const availableCountries = computed(() => localeStore.possibleCountries)
locale.value = localeContext.value !== '' ? localeContext.value : 'nl'
// localeStore.locale = locale.value

onClickOutside(languageSelect, (_event) => {
  languagesOpen.value = false
})

onClickOutside(countrySelect, (_event) => {
  countriesOpen.value = false
})

function setCountry(availableCountry: Region) {
  regionStore.currentRegion = availableCountry
}

const possibleRegions = [
  Region.BELGIUM,
  Region.NETHERLANDS,
  Region.LUXEMBOURG,
]
</script>

<template>
  <div class="z-250 group relative inline-block cursor-pointer rounded-lg border px-3 py-0.5">
    <button class="flex flex-row items-center gap-2 text-base font-semibold">
      {{ locale.toUpperCase() }}
      <div class="chevron group-hover:rotate-180 transform transition">
        <img src="~/assets/images/navbar/chevron-down.svg" alt="Chevron">
      </div>
    </button>

    <div class="absolute right-0 hidden min-w-max transform bg-transparent text-lg group-hover:block">
      <div class="min-w-60 shadow-full group-hover:animate-animated group-hover:animate-zoomIn group-hover:animate-faster dropdown z-50 mt-4 flex flex-col gap-2 rounded-lg bg-white p-4 transition">
        <div>
          <label class="text-sm font-normal opacity-50">{{ t('language.select_language') }}</label>
          <div
            ref="languageSelect"
            class="relative mt-1"
          >
            <button
              aria-expanded="true"
              aria-haspopup="listbox"
              aria-labelledby="listbox-label"
              class="min-w-40 focus:ring-primary focus:border-primary relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-lg shadow-sm focus:outline-none focus:ring-1"
              type="button"
              @click="languagesOpen = true"
              @focus="languagesOpen = true"
            >
              <span class="flex items-center">
                <span class=" block truncate">
                  {{ locale?.toLocaleUpperCase() }}
                </span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2 opacity-50">
                <span class="i-mdi-unfold-more-horizontal" />
              </span>
            </button>
            <ul
              v-if="languagesOpen === true"
              aria-activedescendant="listbox-option-3"
              aria-labelledby="listbox-label"
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white px-2 py-1 text-center shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              role="listbox"
              tabindex="-1"
            >
              <li
                v-for="availableLocale in availableLocales.sort((a, b) => a.localeCompare(b)).reverse()"
                id="listbox-option-0"
                :key="availableLocale"
                class="hover:bg-primary-light hover:text-primary flex-center flex w-full cursor-pointer flex-row gap-8 rounded px-8 py-1 text-center"
                role="option"
                @click="setLocale(availableLocale)"
              >
                {{ availableLocale?.toUpperCase() }}
              </li>
            </ul>
          </div>
        </div>

        <div v-if="disableCountries === false">
          <label class="text-sm font-normal opacity-50">{{ t('language.send_country') }}</label>

          <div
            ref="countrySelect"
            class="relative mt-1"
          >
            <button
              aria-expanded="true"
              aria-haspopup="listbox"
              aria-labelledby="listbox-label"
              class="min-w-40 focus:ring-primary focus:border-primary relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-lg shadow-sm focus:outline-none focus:ring-1"
              type="button"
              @click="countriesOpen = true"
              @focus="countriesOpen = true"
            >
              <span class="flex items-center">
                <span class="flex flex-row items-center space-x-2">
                  <img
                    class="h-6 rounded"
                    :src="flags[regionStore.currentRegion].default"
                    alt="Flag"
                  >
                  <div class="text-black">
                    {{
                      t(`countries.${regionStore.currentRegion}`)
                    }}
                  </div>
                </span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <span class="i-mdi-unfold-more-horizontal" />
              </span>
            </button>
            <ul
              v-if="countriesOpen === true"
              aria-activedescendant="listbox-option-3"
              aria-labelledby="listbox-label"
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white px-2 py-1 text-center shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              role="listbox"
              tabindex="-1"
            >
              <li
                v-for="availableCountry in possibleRegions"
                id="listbox-option-0"
                :key="availableCountry"
                class="hover:bg-primary-light hover:text-primary flex-center flex w-full cursor-pointer flex-row gap-8 rounded px-8 py-1 text-center"
                role="option"
                @click="setCountry(availableCountry)"
              >
                {{ t(`countries.${availableCountry}`) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dropdown::after {
  content:"";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px ;
  border-color: transparent transparent #ffffff transparent;
  z-index:9998;
  opacity: 0;
}
</style>
